@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'font-awesome.min.css';
@import url('http://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,400,600|Raleway:500,700,800');

/*
	Slate by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		xmedium: '(max-width: 1080px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 400px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		containers: 80em,
		grid: ( gutters: 2em ),
		breakpoints: (
			large: (
				containers: 65em,
				grid: ( gutters: 1.5em )
			),
			medium: (
				containers: 90%
			),
			small: (
				small: (90%, true),
				grid: ( gutters: 1.25em )
			)
		)
	));

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-features($p);
		@include color-form($p);
		@include color-icon($p);
		@include color-list($p);
		@include color-post($p);
		@include color-section($p);
		@include color-table($p);
	}

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/box';
	@import 'components/button';
	@import 'components/features';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/post';
	@import 'components/posts';
	@import 'components/section';
	@import 'components/spotlight';
	@import 'components/table';
	@import 'components/wrapper';

// Layout.

	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/footer';
	@import 'layout/navPanel';

	// custom overrides
	@import 'base/styles';