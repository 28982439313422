@import "../libs/vars";
@import "../libs/functions";
@import "../libs/mixins";

///
/// Slate by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Main */

#main {
	@include padding(6em, 0);
	max-width: 1000px;
	margin: 0 auto;

	> .inner {
		> header.major:first-child {
			margin-bottom: 6em;
		}
	}

	@include breakpoint(large) {
		@include padding(4em, 0);

		> .inner {
			> header.major:first-child {
				margin-bottom: 4em;
			}
		}
	}

	@include breakpoint(small) {
		@include padding(2.5em, 0);

		> .inner {
			> header.major:first-child {
				margin-bottom: 2.5em;
			}
		}
	}
}
