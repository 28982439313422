@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Slate by Pixelarity
/// pixelarity.com @pixelarity
/// License: pixelarity.com/license
///

/* Banner */


	#banner {
		@include color-typography(accent1);
		min-height: 35em;
		height: 60vh;
		position: relative;
		background-color: #EFEFEF;

		&:after {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity 2s ease-in-out, visibility 2s');
			background: #EFEFEF;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 2;
		}

		.indicators {
			bottom: 1.5em;
			left: 0;
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			text-align: center;
			width: 100%;
			z-index: 2;

			li {
				cursor: pointer;
				display: inline-block;
				height: 2em;
				overflow: hidden;
				padding: 0;
				position: relative;
				text-indent: 2em;
				width: 2em;

				&:before {
					background: rgba(255,255,255,0.35);
					border-radius: 100%;
					content: '';
					display: inline-block;
					height: 0.8em;
					left: 50%;
					margin: -0.4em 0 0 -0.4em;
					position: absolute;
					text-indent: 0;
					top: 50%;
					width: 0.8em;
				}

				&.visible {
					&:before {
						background: #fff;
					}
				}
			}
		}

		> article {
			@include vendor('transition', 'opacity 2s ease, visibility 2s');
			background-attachment: scroll;
			//background-position: center;
			background-repeat: no-repeat;
			//background-size: cover;
			//background-position: center left;
			background-position: center 0px;
 			background-size: 100%;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			text-align: center;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 0;

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: #333;
				opacity: 0.1;
			}

			.inner {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				z-index: 1;

				> :last-child {
					margin-bottom: 0;
				}
			}

			h2 {
				font-weight: _font(weight-heading-extrabold);
				font-size: 2.75em;
				padding-bottom: 0;
				background-attachment: scroll;
				margin: 0 0 (_size(element-margin) * 0.25) 0;
				text-shadow: #542700 3px 3px 5px;

				&:after {
					display: none;
				}
			}

			img {
				display: none;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: #333;
					opacity: 0.1;
				}
			}

			&.visible {
				opacity: 1;
				visibility: visible;
			}

			&.top {
				z-index: 1;
			}

			&.instant {
				@include vendor('transition', 'none !important');
			}
		}

		body.is-loading & {
			&:after {
				opacity: 1.0;
				visibility: visible;
			}
		}



		@include breakpoint(large) {
			min-height: 28em;
			height: 40vh;

			> article {
				background-attachment: scroll;
				h2 {
					font-size: 2.25em;
					
				}
			}
		}

		@include breakpoint(medium) {
			min-height: 28em;
			height: 40vh;

			> article {
				background-size: cover;
				background-attachment: scroll;
			}
		}

		@include breakpoint(small) {
			min-height: 35em;

			> article {
				.inner {
					width: 90%;
				}

				h2 {
					font-size: 1.75em;
					line-height: 1.25em;
				}
			}
		}

		body.is-mobile & {
			> article {
				background-attachment: scroll;
			}
		}
	}